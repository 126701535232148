import React from "react"
import { Redirect } from "react-router-dom"
import {
  Alert,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import AddressLookupForEcosuiteForm from "@common/form/AddressLookupForEcosuiteForm"
import EcosuiteForm, { EcosuiteArrayFieldTemplate, EcosuiteFieldTemplate, FormError } from "@common/form/EcosuiteForm"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import Schemas from "@common/Schemas"
import ProjectUtils from "@common/utils/ProjectUtils"
import AssetInfo from "../AssetInfo"
import ProjectAdminService from "../ProjectAdminService"
import EcosuiteNumberField from "@common/form/EcosuiteNumberField"
import ReportTable from "./ReportTable"
import { JSONText } from "@common/display/JSONText"
import { importCoda } from "../CodaService"
import { TwoStepDialog } from "@common/input/button/TwoStepDialog"
import { MediaField } from "@dashboard/data/media/MediaField"
import hideSchemaFields from "@common/form/HideSchemaFields"
import ChangeNoteModal from "@common/form/ChangeNoteModal"
import ProjectExportDialog from "@common/project/ProjectExportDialog"
import getUiSchema from "./ProjectUISchema"
import i18n from "src/i18n"
import TimedMessage from "@common/display/TimedMessage"

const { t } = i18n

class ProjectDetails extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedSlackChannel: "",
      autoUpdateOperationalDate: false,
      openOperationalModal: false,
    }

    this.submitFormRef = React.createRef()

    this.editProject = this.editProject.bind(this)
    this.deleteProject = this.deleteProject.bind(this)
    this.formCallback = this.formCallback.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.addressLookup = this.addressLookup.bind(this)
    this.selectSlackChannel = this.selectSlackChannel.bind(this)
    this.archiveProject = this.archiveProject.bind(this)
    this.unarchiveProject = this.unarchiveProject.bind(this)
    this.confirmArchive = this.confirmArchive.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.showTestCodaDialog = false
    this.codaImportResult = undefined
    this.testCodaDialogActiveTab = "1"
    this.successfulCodaImport = false
  }

  confirmDelete(e) {
    this.setState({ showModal: !this.state.showModal })

    e.preventDefault()
  }

  confirmArchive(e) {
    this.setState({ showArchiveModal: true })

    e.preventDefault()
  }

  formCallback(form) {
    this.form = form
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadSchema()
  }

  loadSchema() {
    Schemas.getProjectSchema().then((schema) => {
      this.setState({
        schema: schema,
        formData: this.getFormData(this.props.project, schema),
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.setState({
        formData: this.getFormData(this.props.project, this.state.schema),
        error: undefined,
        success: undefined,
        loading: undefined,
      })
    }
  }

  getFormData(project, schema) {
    if (project && schema) {
      let formData = {}
      const hiddenProperties = ["pausedDate", "cancelledDate"]

      hiddenProperties.concat(Object.keys(schema.properties)).forEach((property) => {
        formData[property] = project[property]
      })
      formData["updated"] = project["updated"]
      return formData
    }
  }

  editProject(form) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("notes.change_note") })
      return
    }

    const prevProject = this.props.project
    const updatedFormDataBase = {
      ...form.formData,
      slackChannel: this.state.selectedSlackChannel || this.props.project.slackChannel,
    }

    let updatedFormData = { ...updatedFormDataBase }

    const currentDate = new Date().toISOString().slice(0, 10)
    const isOperationChosen = prevProject.status !== form.formData.status && form.formData.status === "Operational"
    if (this.state.autoUpdateOperationalDate && isOperationChosen) {
      const operationalDate = isOperationChosen ? currentDate : prevProject.milestones.operational
      updatedFormData = {
        ...form.formData,
        milestones: {
          ...form.formData.milestones,
          operational: operationalDate,
        },
      }
    }

    this.setState({ error: undefined, success: false, loading: true, formData: updatedFormData })
    const updatedForm = { ...form, formData: updatedFormData }

    ProjectAdminService.editProject(this.props.project.code, updatedForm, this.state.note)
      .then((data) => {
        this.setStateIfMounted({ success: true, loading: undefined, note: "" })
        this.props.projectChanged(data.project)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: undefined })
      })
  }

  deleteProject(e) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("notes.change_note") })
      return
    }

    this.setState({ error: undefined, loading: true })
    ProjectAdminService.deleteProject(this.props.project.code, this.state.note)
      .then(() => {
        this.setStateIfMounted({ loading: false, showModal: false, note: "" })
        this.props.projectDeleted(this.props.project)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, loading: false, showModal: false })
      })
    e.preventDefault()
  }

  archiveProject(e) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("notes.change_note") })
      return
    }

    const payload = { formData: { ...this.props.project, archived: true } }
    this.setState({ error: undefined, success: false, loading: true, formData: payload })
    ProjectAdminService.editProject(this.props.project.code, payload, this.state.note)
      .then((data) => {
        this.setStateIfMounted({ success: true, loading: undefined, note: "" })
        this.props.projectChanged(data.project)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: undefined })
      })
    this.setState({ showArchiveModal: false })
    e.preventDefault()
  }

  unarchiveProject(e) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("notes.change_note") })
      return
    }

    const payload = { formData: { ...this.props.project, archived: false } }
    this.setState({ error: undefined, success: false, loading: true, formData: payload })
    ProjectAdminService.editProject(this.props.project.code, payload, this.state.note)
      .then((data) => {
        this.setStateIfMounted({ success: true, loading: undefined, note: "" })
        this.props.projectChanged(data.project)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: undefined })
      })
    this.setState({ showArchiveModal: false })
    e.preventDefault()
  }

  addressLookup(props) {
    const form = this.form

    if (!this.state.formData) {
      return <Loading />
    }

    return (
      <div className="form-group field field-object">
        <Input
          type="text"
          name="address"
          className="form-group field field-string"
          value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}
          disabled={this.props.readonly}
        />
        <AddressLookupForEcosuiteForm
          form={form}
          formData={this.state.formData}
          parent={this}
          disabled={this.props.readonly}
        />
      </div>
    )
  }

  /**
   Determines which fields of a schema should be hidden based on the user's permissions.
   @param {string[]} permissions - An array of permissions granted to the user.
   @returns {string[]} - An array of field names to hide from the schema based on the user's permissions.
   */
  getSchemaFieldsToHideBasedOnPermission(permissions) {
    const ret = []
    if (!permissions?.includes("notes")) {
      ret.push("notes")
    }
    return ret
  }

  /**
   * @param {(() => void) | undefined} callback - what to do after the state updates
   */
  toggleChangeNoteModal(callback) {
    this.setStateIfMounted({ isChangeNoteOpen: !this.state.isChangeNoteOpen }, callback)
  }

  /**
   * @param {(() => void) | undefined} callback - what to do after the state updates
   */
  toggleOpModal(callback) {
    this.setStateIfMounted({ openOperationalModal: true }, callback)
  }

  updateOpDate() {
    this.setState({ autoUpdateOperationalDate: true })
    this.setState({ openOperationalModal: false })
    this.toggleChangeNoteModal()
  }

  closeModal() {
    this.setState({ openOperationalModal: false })
    this.setState({ autoUpdateOperationalDate: false })
    this.toggleChangeNoteModal()
  }

  openModals() {
    if (this.state.formData.status === "Operational" && this.props.project.status !== "Operational") {
      this.setState({ openOperationalModal: true })
    } else {
      this.toggleChangeNoteModal()
    }
  }

  toggleProjectExportDialog() {
    this.setStateIfMounted({ showExportDialog: !this.state.showExportDialog })
  }

  selectSlackChannel(event) {
    this.setState({ selectedSlackChannel: event.target.value })
  }

  renderContent() {
    const { t } = i18n
    const slackChannels = this.props.slackChannels
    if (this.state.deleted) {
      return <Redirect to="/projects" />
    }
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.schema && this.props.project) {
      const isPermitted = this.props.groups.includes("data-write") || this.props.groups.includes("data-read")
      return (
        <div className="ecogy-form project-tab-content-area">
          <TwoStepDialog
            isOpen={this.state.showTestCodaDialog}
            type={"simple"}
            title={t("buttons.import_from_coda")}
            body={
              <>
                <p>
                  {t("data.dialogs.step_one")} <span style={{ fontWeight: "bold" }}>{t("data.dialogs.step_two")}</span>
                </p>
              </>
            }
            onCancel={() =>
              this.setState({
                showTestCodaDialog: false,
                codaImportResult: undefined,
                successfulCodaImport: false,
              })
            }
            displayText={t("data.dialogs.display_text")}
            displayTextColor={"primary"}
            confirmText={t("data.project_details.test")}
            onConfirm={async () => {
              const results = await importCoda(this.props.project.code)
              this.setStateIfMounted({
                codaImportResult: results,
                successfulCodaImport: results.validationErrors === undefined || results.validationErrors.length === 0,
              })
            }}
            onTwoStep={async () => {
              await importCoda(this.props.project.code, true)

              // Assuming successful operation, reset the dialog.
              this.setState({
                showTestCodaDialog: false,
                codaImportResult: undefined,
                testCodaDialogActiveTab: "1",
                successfulCodaImport: false,
              })

              // The project isn't necessarily always changed, but this is a quick solution.
              // TODO: Make this a bit smarter, only reload what is necessary.
              this.props.projectChanged()
            }}
            twoStepText={t("data.project_details.apply_import")}
            twoStepTooltip={t("data.project_details.apply_import")}
            displayResult={this.state.codaImportResult && this.renderCodaDiff()}
            displayResultBgColor={"transparent"}
            disableTwoStep={!this.state.successfulCodaImport}
          />
          <Row>
            <Col sm={9}>
              <EcosuiteForm
                idPrefix={"ecogy_project" + (this.props.project ? this.props.project.code : "")}
                schema={hideSchemaFields(
                  this.getSchemaFieldsToHideBasedOnPermission(this.props.groups),
                  this.state.schema,
                )}
                uiSchema={getUiSchema.call(this, this.state.formData, this.props.orgUXSettings)}
                formData={this.state.formData}
                onSubmit={this.editProject}
                onChange={this.formDataChanged}
                FieldTemplate={EcosuiteFieldTemplate}
                ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
                formMounted={this.formCallback}
                disabled={this.props.readonly}
                fields={{ media: MediaField, NumberField: EcosuiteNumberField }}
                widgets={{
                  addressLookup: this.addressLookup,
                }}
                formContext={{
                  startDate: this.props.project ? ProjectUtils.getProjectStartDate([this.props.project]) : undefined,
                  productionStartDate: this.props.project?.productionStartDate,
                }}
              >
                <Row className="ecogy-form-buttons">
                  <Col className="message-section" sm="10">
                    {this.renderMessages()}
                  </Col>

                  <Col className="button-section">
                    <Button color="primary" onClick={() => this.openModals()} disabled={this.props.readonly}>
                      {t("buttons.submit")}
                    </Button>
                    <Button color="danger" onClick={this.confirmDelete} disabled={this.props.readonly}>
                      {t("buttons.delete")}
                    </Button>
                    <Button color="primary" onClick={() => this.toggleProjectExportDialog()}>
                      {t("buttons.export")}
                    </Button>
                    {!this.props.project.archived && isPermitted ? (
                      <Button color="danger" onClick={this.confirmArchive}>
                        {t("buttons.archive")}
                      </Button>
                    ) : this.props.project.archived ? (
                      <Button color="danger" onClick={this.confirmArchive}>
                        {t("buttons.unarchive")}
                      </Button>
                    ) : null}
                  </Col>
                </Row>

                {/* react-jsonschema-form doesn't expose submit() or requestSubmit() inside of its ref */}
                {/* So a reference to this button is needed to submit from inside a modal / nested component */}
                <button ref={(ref) => (this.submitFormRef = ref)} style={{ display: "none" }} />
              </EcosuiteForm>

              {this.renderMessages()}
              {slackChannels && (
                <div className="row form-group field">
                  <Label className="control-label col-sm-2 col-form-label">{t("labels.slack_channel")}</Label>
                  <div className="col-sm-10">
                    <Input type="select" value={this.state.slackChannel} onChange={this.selectSlackChannel}>
                      <option value="">
                        {this.props.project.slackChannel || `${t("labels.select_slack_channel")}`}
                      </option>
                      {slackChannels.map((channel) => (
                        <option key={channel.id} value={channel.name}>
                          {channel.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
              )}
            </Col>
            <Col sm={3} className="project-side-panel">
              <div className="project-side-panel-content">
                <AssetInfo asset={this.props.project} predictedGeneration={this.props.predictedGeneration} />
              </div>

              <div className="side-panel">
                <ReportTable project={this.props.project} />
              </div>
            </Col>
          </Row>

          {/* Modals */}
          {this.renderModal()}
          <ChangeNoteModal
            isOpen={this.state.isChangeNoteOpen}
            toggle={() => this.toggleChangeNoteModal()}
            submit={() => this.toggleChangeNoteModal(() => this.submitFormRef.click())}
            canSubmit={!!this.state.note}
          >
            <Col className="audit-section">
              <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
              <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            </Col>
          </ChangeNoteModal>
          <ProjectExportDialog
            project={this.props.project}
            showExportDialog={this.state.showExportDialog}
            setShowExportDialog={() => {
              this.toggleProjectExportDialog()
            }}
          />
          <Modal isOpen={this.state.showArchiveModal}>
            <ModalBody>
              <p className="mb-3">
                {this.props.project.archived
                  ? t("data.confirmation_msg.confirm_unarchive")
                  : t("data.confirmation_msg.confirm_archive")}
              </p>
              <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note mb-3" />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={(e) => {
                  if (this.props.project.archived) {
                    this.unarchiveProject(e)
                  } else {
                    this.archiveProject(e)
                  }
                }}
              >
                {this.props.project.archived ? "Unarchived" : t("buttons.archive")}
              </Button>
              <Button color="danger">{t("buttons.cancel")}</Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.openOperationalModal}>
            <ModalBody>{t("data.confirmation_msg.update_operational_date")}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.updateOpDate()}>
                {t("labels.yes")}
              </Button>
              <Button color="danger" onClick={() => this.closeModal()}>
                {t("labels.no")}
              </Button>
            </ModalFooter>
          </Modal>
          {/* // Hiding this for now */}
          {/* <Button color="primary" onClick={() => this.setState({ showTestCodaDialog: false })}>
            {t("buttons.import_from_coda")}
          </Button> */}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderMessages() {
    return (
      <React.Fragment>
        {this.state.success ? (
          <TimedMessage delay={8000}>
            <Alert color="info">{t("alertsInfo.project_updated")}</Alert>
          </TimedMessage>
        ) : null}
        <FormError
          error={this.state.error}
          toggle={() => {
            this.setStateIfMounted({ error: null })
          }}
        />
      </React.Fragment>
    )
  }

  /**
   * Render the Coda test/difference dialog.
   * @returns {JSX.Element}
   */
  renderCodaDiff() {
    /**
     * Toggle the active Coda tab.
     * @param tab - The new tab to toggle.
     */
    const toggleTab = (tab) => {
      this.setStateIfMounted({
        testCodaDialogActiveTab: tab,
      })
    }

    /**
     * Get the appropriate tab class name.
     * @param tab - The tab to evaluate.
     * @returns {string} - The class name.
     */
    const getClassName = (tab) => {
      if (this.state.testCodaDialogActiveTab === tab) {
        return "active-dialog-tab"
      }
      return "inactive-dialog-tab"
    }
    const projectChanges = this.state.codaImportResult.changeMap.projectChanges
    const recordChanges = this.state.codaImportResult.changeMap.recordChanges
    const errors = this.state.codaImportResult.validationErrors

    const projectDiff = projectChanges.projectDiff
    const sitesDiff = projectChanges.sitesDiff
    const systemsDiff = projectChanges.systemsDiff
    const recordsDiff = recordChanges.recordsDiff

    const noProblemsDetectedElement = <Alert color={"success"}>{t("alertsInfo.no_problems_detected")}</Alert>
    const noChangesDetectedElement = <Alert color={"success"}>{t("alertsInfo.no_changes_detected")}</Alert>

    // Quickly check that there are any changes.
    if (
      (projectDiff === undefined || Object.keys(projectDiff).length === 0) &&
      (sitesDiff === undefined || Object.keys(sitesDiff).length === 0) &&
      (systemsDiff === undefined || Object.keys(systemsDiff).length === 0) &&
      (recordsDiff === undefined || Object.keys(recordsDiff).length === 0)
    ) {
      // If there are no differences, then return a generic message.
      return (
        <>
          {errors && errors.length > 0 ? (
            <Alert color={"danger"}>
              <JSONText object={errors} />
            </Alert>
          ) : (
            noChangesDetectedElement
          )}
        </>
      )
    }

    // The tab names for each tab content.
    const projectTab = "1"
    const sitesTab = "2"
    const systemsTab = "3"
    const recordsTab = "4"
    const schemaErrorsTab = "5"

    const { t } = i18n

    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink className={getClassName(projectTab)} onClick={() => toggleTab(projectTab)}>
              {t("links.project")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={getClassName(sitesTab)} onClick={() => toggleTab(sitesTab)}>
              {t("links.sites")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={getClassName(systemsTab)} onClick={() => toggleTab(systemsTab)}>
              {t("links.systems")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={getClassName(recordsTab)} onClick={() => toggleTab(recordsTab)}>
              {t("links.records")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={getClassName(schemaErrorsTab)} onClick={() => toggleTab(schemaErrorsTab)}>
              {t("links.schema_errors")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.testCodaDialogActiveTab}>
          <TabPane tabId={projectTab}>
            {projectDiff === undefined || Object.keys(projectDiff).length === 0 ? (
              noChangesDetectedElement
            ) : (
              <Alert color={"warning"}>
                <JSONText object={projectDiff} />
              </Alert>
            )}
          </TabPane>
          <TabPane tabId={sitesTab}>
            {sitesDiff === undefined || Object.keys(sitesDiff).length === 0 ? (
              noChangesDetectedElement
            ) : (
              <Alert color={"warning"}>
                <JSONText object={sitesDiff} />
              </Alert>
            )}
          </TabPane>
          <TabPane tabId={systemsTab}>
            {systemsDiff === undefined || Object.keys(systemsDiff).length === 0 ? (
              noChangesDetectedElement
            ) : (
              <Alert color={"warning"}>
                <JSONText object={systemsDiff} />
              </Alert>
            )}
          </TabPane>
          <TabPane tabId={recordsTab}>
            {recordsDiff === undefined || Object.keys(recordsDiff).length === 0 ? (
              noChangesDetectedElement
            ) : (
              <Alert color={"warning"}>
                <JSONText object={recordsDiff} />
              </Alert>
            )}
          </TabPane>
          <TabPane tabId={schemaErrorsTab}>
            {errors === undefined || errors.length === 0 ? (
              noProblemsDetectedElement
            ) : (
              <Alert color={"danger"}>
                <JSONText object={errors} />
              </Alert>
            )}
          </TabPane>
        </TabContent>
      </>
    )
  }

  renderModal() {
    const { t } = i18n
    return (
      <Modal isOpen={this.state.showModal} toggle={this.confirmDelete}>
        <ModalHeader toggle={this.confirmDelete}>{`Delete Project: ${this.props.project.code}?`}</ModalHeader>
        <ModalBody>
          <p>
            {t("data.modal_body.delete_ques", {
              projectName: this.props.project.name,
              projectCode: this.props.project.name,
            })}
          </p>
          <div>
            {t("data.project_details.to_delete_project")}
            <ul>
              <li>{t("data.project_details.delete_project")}</li>
              <li>{t("data.project_details.delete_record")}</li>
              <li>{t("data.project_details.delete_events")}</li>
            </ul>
            {t("data.modal_body.storage_info_title")}
            <ul>
              <li>{t("data.financial_data")}</li>
              <li>{t("data.connectivity_data")}</li>
              <li>{t("data.energy_data")}</li>
            </ul>
            <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
            <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            <br />
            <span>
              {t("data.modal_body.delete_alert")}{" "}
              <b style={{ color: "red" }}>{`Delete Project ${this.props.project.name}`}</b>
            </span>
            <Input
              value={this.state.deleteConfirmation}
              onChange={(e) => this.setState({ deleteConfirmation: e.target.value })}
              className="audit-note"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={this.deleteProject}
            disabled={!this.state.note || this.state.deleteConfirmation !== `Delete Project ${this.props.project.name}`}
          >
            {t("buttons.delete_project")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.setState({ showModal: false })
            }}
          >
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ProjectDetails
