import { Button } from "@aws-amplify/ui-react"
import * as React from "react"
import { SubmitHandler, useForm, useFieldArray, FormProvider } from "react-hook-form"
import { SelectField } from "@aws-amplify/ui-react"
import { Organization, useOrganization, useUpdateOrganization, extAPI } from "src/services/organization"
import { toast } from "react-toastify"
import { formatData, formatForm } from "src/SuperAdmin/helpers"
import OrganizationFormFields, { OrganizationDataSharingFeilds, OrganizationAdvancedFeilds, OrganiztionEnergyApiFields } from "src/SuperAdmin/OrganizationFormFields"
import { S3LogoHandler } from "src/SuperAdmin/S3LogoHandler"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { Table } from "ka-table"

interface Props {
  organizationId: string,
  projects: Project[]
}

export default function OrganizationSettingsForm(props: Props) {
  const { organizationId, projects } = props
  const { data: thisOrg, isLoading, isError } = useOrganization(organizationId)
  const { mutateAsync, isLoading: isUpdating } = useUpdateOrganization()
  const [submitting, setSubmitting] = React.useState(false)
  const [tabs, setTab] = React.useState("general")

  const form = useForm<Organization>({
    defaultValues: {},
  })
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    reset,
    setValue,
    register,
  } = form
  const { fields, append, remove } = useFieldArray({
    control,
    name: "energyAPIs",
  });
  const watchEnableSharing = watch("dataSharing.enableSharing", "Do not share my data")

  React.useEffect(() => {
    if (organizationId === thisOrg?.id) {
      reset(formatData(thisOrg))
    }
  }, [thisOrg, organizationId])

  const onSubmit: SubmitHandler<Organization> = async (data) => {
    setSubmitting(true)
    const imageUrl = await S3LogoHandler(data.settings?.logoUrl)
    try {
      await mutateAsync(formatForm(data, imageUrl) as Organization)
      toast.success("Organization updated succesfully!")
      setSubmitting(false)
    } catch (e) {
      toast.error("Failed to update organization!")
      setSubmitting(false)
    }
  }


  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error while fetching organization</div>

  function renderDataSharingTable() {
    if (!projects) { return }
    return (
      <div className="table custom-theme-demo">
        <Table
          data={projects}
          rowKeyField={"Project Code"}
          columns={[
            {
              key: "name",
              title: "Project Name",
              width: 250,
            },
            {
              key: "code",
              title: "Project Code",
              width: 180,
            },
            {
              key: "Partners",
              title: "Partners",
              width: 180,
            },
            {
              key: "Earnings",
              title: "Earnings",
              width: 180,
            }
          ]}
        />
      </div>
    )
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="inputs-theme" style={{ height: "86vh", overflowY: "auto" }}>
          <Nav tabs>
            <NavItem>
              <NavLink className={"general"} onClick={() => setTab("general")}>
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={"Advanced"} onClick={() => setTab("Advanced")}>
                Advanced
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={"Data sharing"} onClick={() => setTab("Data sharing")}>
                Data sharing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={"Energy API's"} onClick={() => setTab("Energy API's")}>
                Energy API's
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabs} style={{marginInline: "20px"}}>
            <TabPane tabId={"general"}>
              <OrganizationFormFields
                control={control}
                errors={errors}
                organization={thisOrg}
                reset={reset}
                setValue={setValue}
              />
            </TabPane>
            <TabPane tabId={"Advanced"}>
              <OrganizationAdvancedFeilds
                control={control}
                errors={errors}
                organization={thisOrg}
                reset={reset}
                setValue={setValue}
              />
            </TabPane>
            <TabPane tabId={"Data sharing"}>
              <OrganizationDataSharingFeilds
                control={control}
                errors={errors}
                organization={thisOrg}
                reset={reset}
                setValue={setValue}
              />
              {watchEnableSharing !== "Do not share my data" ? renderDataSharingTable() : null}
            </TabPane>
            <TabPane tabId={"Energy API's"}>
              <h3 style={{ marginTop: "8px" }}>3rd party APIs</h3>
              <p>Use this page to configure 3rd party energy apis that your org requires</p>
              <SelectField
                label="Select API:"
                onChange={(e) => {
                  if (e.target.value === "Select") { return }
                  append({ APIname: e.target.value })
                }
                }
              >
                <option value={"Select"}>Select</option>
                <option value={"Locus Energy"}>LocusEnergy</option>
              </SelectField>
              <OrganiztionEnergyApiFields
                control={control}
                errors={errors}
                organization={thisOrg}
                reset={reset}
                setValue={setValue}
                fields={fields}
                register={register}
                remove={remove}
              />
            </TabPane>
          </TabContent>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "8px", paddingRight: "12px" }}>
          <Button
            variation="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={submitting}
            loadingText="Updating organization..."
            isDisabled={!isDirty}
          >
            Update Organization
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
