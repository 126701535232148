import React from "react"

import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import EcosuiteView from "@common/module/EcosuiteView"
import Icon from "@common/display/Icon"

import ProjectAdminService from "./ProjectAdminService"

import CreateProject from "./projects/CreateProject"
import ProjectDetails from "./projects/ProjectDetails"
import ProjectSites from "./sites/ProjectSites"
import ProjectSystems from "./systems/ProjectSystems"
import SalesforceProjectImportDialog from "@dashboard/data/project/SalesforceProjectImportDialog"

import "./Project.css"
import i18n from "src/i18n"
import SlackServices from "@common/project/SlackServices"

const { t } = i18n

export default class ProjectView extends EcosuiteView {
  constructor(props) {
    super(props)
    this.state = { projects: this.props.projects, slackChannels: [] }
    this.projectAdded = this.projectAdded.bind(this)
    this.projectChanged = this.projectChanged.bind(this)
    this.projectDeleted = this.projectDeleted.bind(this)
    this.sfProjectData = {}
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadProjects() // Load the latest project data
    this.getSlackChannels()
  }

  loadProjects(project) {
    // We always notify the app to update projects, there may have been recent changes made elsewhere that were loaded into admin and that should be made in the main app
    // We could do a deepEquals comparison with the this.props.projects to avoid an unnecessary API call to load the projects but this isn't expected to happen often
    this.props.actions.loadProjects(project ? project.code : undefined) // Notify the main app that there have been changes to the projects

    // Also refresh the predicted generation
    this.loadPredictedGeneration()
  }

  loadPredictedGeneration() {
    ProjectAdminService.getPredictedGeneration().then((json) => {
      this.setStateIfMounted({
        predictedGeneration: json.projects,
      })
    })
  }

  projectAdded(project) {
    this.loadProjects(project) // Just reload all the project. The ProjectDetails and ProjectSites tabs rely on this to be up to date as does the ProjectSelector. Could be smarter but loading the projects is cheap
    this.props.actions.selectProject(project)
  }

  projectChanged(project) {
    this.loadProjects(project) // Just reload all the project. The ProjectDetails and ProjectSites tabs rely on this to be up to date as does the ProjectSelector. Could be smarter but loading the projects is cheap
  }

  projectDeleted() {
    this.props.actions.selectProject()
    this.loadProjects() // Just reload all the project. The ProjectDetails and ProjectSites tabs rely on this to be up to date as does the ProjectSelector. Could be smarter but loading the projects is cheap
  }

  getPredictedGeneration() {
    if (this.props.project && this.state.predictedGeneration) {
      return this.state.predictedGeneration[this.props.project.code]
    }
  }

  getSlackChannels() {
    SlackServices.getSlackChannels().then((data) => {
      this.setState({ slackChannels: data })
    })
  }
}

export class ProjectTabs extends ProjectView {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)

    let activeTab
    switch (window.location.hash) {
      case "#sites":
        activeTab = "sites"
        break
      case "#systems":
        activeTab = "systems"
        break
      default:
        activeTab = "project"
    }
    this.state = {
      activeTab: activeTab,
    }
  }

  projectAdded(project) {
    super.projectAdded(project)
    this.setStateIfMounted({ activeTab: "sites" })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleSalesforceImportDialog() {
    this.setStateIfMounted({ showSalesforceImportDialog: !this.state.showSalesforceImportDialog })
  }

  isReadOnly() {
    return !this.props.groups.includes("data-write")
  }

  render() {
    const readonly = this.isReadOnly()
    const isProjectVerified = this.props.project.verified
    const verifiedTooltip = "Data has been verified"
    if (!this.props.project) {
      return null
    }
    if (!this.props.project.code) {
      return <CreateProject formData={this.sfProjectData} projectAdded={this.projectAdded} groups={this.props.groups} />
    }
    return (
      <>
        <div className="project-tabs">
          <SalesforceProjectImportDialog
            showDialog={this.state.showSalesforceImportDialog}
            setShowDialog={() => {
              this.toggleSalesforceImportDialog()
            }}
            dialogOnConfirm={(project) => {
              this.sfProjectData = project
              this.props.actions.selectProject({})
            }}
          />
          <Nav tabs style={{ width: "100%" }}>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "project" ? "active" : null}
                onClick={() => {
                  this.toggle("project")
                }}
                id="project"
              >
                {t("links.project")}
                {this.props.project.verified && <Icon icon="verified" className="verified" />}
              </NavLink>
              {isProjectVerified && <UncontrolledTooltip target="project">{verifiedTooltip}</UncontrolledTooltip>}
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "sites" ? "active" : null}
                onClick={() => {
                  this.toggle("sites")
                }}
                id="sites"
              >
                {t("links.sites")}
                {this.props.project.verified && <Icon icon="verified" className="verified" />}
              </NavLink>
              {isProjectVerified && <UncontrolledTooltip target="sites">{verifiedTooltip}</UncontrolledTooltip>}
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "systems" ? "active" : null}
                onClick={() => {
                  this.toggle("systems")
                }}
                id="systems"
              >
                {t("links.systems")}
                {this.props.project.verified && <Icon icon="verified" className="verified" />}
              </NavLink>
              {isProjectVerified && <UncontrolledTooltip target="systems">{verifiedTooltip}</UncontrolledTooltip>}
            </NavItem>
            {!readonly && (
              <>
                <NavItem className="ms-auto">
                  <Button
                    color="ecogy"
                    className="pull-end"
                    onClick={() => {
                      this.toggleSalesforceImportDialog()
                    }}
                  >
                    {t("labels.new_project_from_salesforce")}
                  </Button>
                  &nbsp;
                  <Button
                    id="create-new-project"
                    color="ecogy"
                    className="pull-end"
                    onClick={() => {
                      this.props.actions.selectProject({})
                    }}
                  >
                    {t("labels.new_project")}
                  </Button>
                </NavItem>
                <UncontrolledTooltip target="create-new-project">
                  {t("data.dialogs.create_new_site")}
                </UncontrolledTooltip>
              </>
            )}
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="project">
              <Row>
                <Col sm="12">
                  <ProjectDetails
                    groups={this.props.groups}
                    project={this.props.project}
                    predictedGeneration={this.getPredictedGeneration()}
                    projectChanged={this.projectChanged}
                    projectDeleted={this.projectDeleted}
                    readonly={readonly}
                    projects={this.props.allProjects}
                    slackChannels={this.state.slackChannels}
                    orgUXSettings={this.props.orgUXSettings}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="sites">
              <Row>
                <Col sm="12">
                  <ProjectSites
                    project={this.props.project}
                    predictedGeneration={this.getPredictedGeneration()}
                    projectChanged={this.projectChanged}
                    readonly={readonly}
                    groups={this.props.groups}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="systems">
              <Row>
                <Col sm="12">
                  <ProjectSystems
                    project={this.props.project}
                    predictedGeneration={this.getPredictedGeneration()}
                    projectChanged={this.projectChanged}
                    autoCompletes={this.props.autoCompletes}
                    readonly={readonly}
                    verifiedTooltip={verifiedTooltip}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </>
    )
  }
}
